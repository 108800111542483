import React, { Fragment, Component, useState, useEffect } from "react";
import Footer from "../layout/footer";
import { db, tssrCol } from "../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useRef } from "react";
import Header from "../layout/header";

const conSubTitle = "TSSR";
const conTitle = "ATC Registration";
const btnText = "submit";

const atcRequestCollectionName = "Atc_Requests";

const AtcRegRequestPage = () => {
  const centre_head = useRef();
  const centre_name = useRef();
  const phone_no = useRef();
  const place = useRef();
  const city = useRef();
  const email = useRef();
  const pincode = useRef();
  const [selectedDistrict, setSelectedDistrict] = useState("Select district");
  // const selectedDistrict = useRef("Select district");
  const selectedState = useRef("Select state");

  const handleDropDownListSelectionDistrict = (event) => {
    // selectedDistrict.current = event.target.value;
    setSelectedDistrict(event.target.value);
  };

  const handleDropDownListSelectionState = (event) => {
    selectedState.current = event.target.value;
    setSelectedDistrict("Select district");
    // selectedDistrict.current = "Select district";
    fetchDistricts(event.target.value);
  };

  const [states, setStates] = useState([]); // State to store JSON data
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    // Function to fetch JSON data
    const fetchStates = async () => {
      try {
        const response = await fetch("/assets/json/states_districts.json"); // Relative path to the JSON file
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const statesDistricts = await response.json(); // Parse JSON
        // Extract and map states
        const statesList = statesDistricts.states.map((item) => item.state);
        setStates(statesList);
        console.log(states);
      } catch (err) {
        setDistricts([]);
        console.log(err.message); // Set error message to state
      }
    };

    fetchStates(); // Call the fetch function
  }, []);

  const fetchDistricts = async (state) => {
    try {
      const response = await fetch("/assets/json/states_districts.json"); // Relative path to the JSON file
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const statesDistricts = await response.json(); // Parse JSON
      // Find the state and get its districts
      const selectedState = statesDistricts.states.find(
        (st) => st.state === state
      );

      if (!selectedState) {
        throw new Error("State not found");
      }

      setDistricts(selectedState.districts); // Update districts in state
    } catch (err) {
      console.log(err.message); // Handle errors
    }
  };

  const handleSubmit = (e) => {
    const form = document.getElementById("tssr-form"); // Get form by ID
    const inputs = form.querySelectorAll("input, textarea"); // Get all inputs and textareas

    let allFieldsFilled = true;

    inputs.forEach((input) => {
      if (!input.value.trim()) {
        allFieldsFilled = false;
      }
    });

    if (
      selectedState.current.length == 0 ||
      selectedState.current == null ||
      selectedState.current == "Select state"
    ) {
      allFieldsFilled = false;
    }

    if (
      selectedDistrict.length == 0 ||
      selectedDistrict == null ||
      selectedDistrict == "Select district"
    ) {
      allFieldsFilled = false;
    }

    if (!allFieldsFilled) {
      alert("Please fill out all fields.");
      return;
    }

    addDoc(collection(db, atcRequestCollectionName), {})
      .then((e) => {
        setDoc(doc(db, atcRequestCollectionName, e.id), {
          id: e.id,
          centre_head: centre_head.current,
          centre_name: centre_name.current,
          phone_no: phone_no.current,
          place: place.current,
          email: email.current,
          city: city.current,
          district: selectedDistrict,
          state: selectedState.current,
          pincode: pincode.current,
          timestamp: Date.now().toString(),
        }).then(() => {
          alert("Your request is submitted.");
          // resettoempty();
          // document.getElementById("tssr-form").reset();
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const resettoempty = () => {
    centre_head.current = "";
    centre_name.current = "";
    phone_no.current = "";
    place.current = "";
    email.current = "";
    city.current = "";
    pincode.current = "";
  };

  return (
    <Fragment>
      <Header />
      <div className="map-address-section padding-tb section-bg"></div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form className="contact-form" id="tssr-form">
              {/* ///////////////////////// */}

              {/* Email  */}
              <div className="form-group">
                <label className="admin-page-label">Email</label>
                <input
                  value={email.current}
                  onChange={(e) => (email.current = e.target.value)}
                  type="text"
                  placeholder="Email *"
                />
              </div>

              {/* Centre Head  */}
              <div className="form-group">
                <label className="admin-page-label">Centre Head</label>
                <input
                  value={centre_head.current}
                  onChange={(e) => (centre_head.current = e.target.value)}
                  type="text"
                  placeholder="Centre Head *"
                />
              </div>
              {/* Centre Name */}
              <div className="form-group">
                <label className="admin-page-label">Centre Name</label>
                <input
                  value={centre_name.current}
                  onChange={(e) => (centre_name.current = e.target.value)}
                  type="text"
                  placeholder="Centre Name *"
                />
              </div>
              {/* Phone No */}
              <div className="form-group">
                <label className="admin-page-label">Phone No</label>
                <input
                  value={phone_no.current}
                  onChange={(e) => (phone_no.current = e.target.value)}
                  type="text"
                  placeholder="Phone No *"
                />
              </div>
              {/* Place */}
              <div className="form-group">
                <label className="admin-page-label">Place</label>
                <input
                  value={place.current}
                  onChange={(e) => (place.current = e.target.value)}
                  type="text"
                  placeholder="Place"
                />
              </div>
              {/* City */}
              <div className="form-group">
                <label className="admin-page-label">City</label>
                <input
                  value={city.current}
                  onChange={(e) => (city.current = e.target.value)}
                  type="text"
                  placeholder="City"
                />
              </div>

              {/* States */}
              <div className="form-group">
                <label htmlFor="dropdown">Select State:</label>
                <select
                  id="dropdown"
                  value={selectedState.current}
                  onChange={handleDropDownListSelectionState}
                >
                  <option value="Select state">Select state</option>
                  {states.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </select>

                {/* {selectedState.current && (
                  <p>You selected: {selectedState.current}</p>
                )} */}
              </div>

              {/* District */}
              <div className="form-group">
                <label htmlFor="dropdown">Select District:</label>
                <select
                  id="dropdown"
                  value={selectedDistrict}
                  onChange={handleDropDownListSelectionDistrict}
                >
                  <option value="Select district">Select district</option>
                  {districts.map((dis) => (
                    <option value={dis}>{dis}</option>
                  ))}
                </select>

                {/* {selectedDistrict &&
                  selectedDistrict != "Select state" && (
                    <p>You selected: {selectedDistrict}</p>
                  )} */}
              </div>
              {/* Pincode */}
              <div className="form-group">
                <label className="admin-page-label">Pincode</label>
                <input
                  value={pincode.current}
                  onChange={(e) => (pincode.current = e.target.value)}
                  type="text"
                  placeholder="Pincode"
                />
              </div>

              {/* submit button  */}
              <div className=" w-100 text-center">
                {/* <button className="lab-btn" onSubmit={handleSubmit}>
                  <span>{btnText}</span>
                </button> */}
                <input
                  type={"button"}
                  value={"submit"}
                  onClick={handleSubmit}
                  className="file-upload-btn"
                />
              </div>
              {/* ///////////////////////////////// */}
            </form>
            {/* <input type={'button'} value={'test'} onClick={test}/> */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default AtcRegRequestPage;
