import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import GoogleMap from "../component/sidebar/googlemap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

import { Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const subTitle = "About Us";
const title = "We're Always Eager To Hear From You!";

const aboutSubTitle = "About TSSR";
const aboutTitle = "Best Education Franchise Provider.";

const WhatWeDoSubTitle = "Study Centres";
const WhatWeDoTitle = "We Have Done";

const contactList = [
  {
    imgUrl: "assets/images/icon/01.png",
    imgAlt: "contact icon",
    title: "Office Address",
    // desc: "Engapuzha,, Calicut, Kerala, India",
    desc: "TSSR Council, TSSR Bhavan, Thamarassery, Calicut, Kerala - 673573",
  },
  {
    imgUrl: "assets/images/icon/02.png",
    imgAlt: "contact icon",
    title: "Phone number",
    desc: "+91 9400867461",
  },
  {
    imgUrl: "assets/images/icon/03.png",
    imgAlt: "contact icon",
    title: "Send email",
    desc: "tssrcouncil@gmail.com",
  },
  {
    imgUrl: "assets/images/icon/04.png",
    imgAlt: "contact icon",
    title: "Our website",
    desc: "www.tssrcouncil.com",
  },
];

const aboutList = [
  {
    // imgUrl: "assets/images/about/icon/01.jpg",
    // imgAlt: "about icon ",
    title: "Our Mission",
    desc: "Technical Study and Skill Research (TSSR) Council is Providing Franchise to institutions which conduct short term courses and skilled training courses in Technical and other disciplines. We have franchise to more than 1400+ courses and the students who complete the trainining in the institute which have our franchise will receive iso and other authorities recognized certificates.",
  },
  {
    // imgUrl: "assets/images/about/icon/03.jpg",
    // imgAlt: "about icon rajibraj91 rajibraj",
    title: "Our Vision",
    // desc: "TSSR COUNCIL has emerged to be one of the fastest growing education franchise organisation in India with its Registered Office at New Delhi and Central Administrative Office located in the South Western tip of India, Calicut, is the city of the Indian State of Kerala, Today we have got very extensive and wide network and a leading Global Talent Development Organization with more than 1000+ Authorised Training Centres, spread all over Kerala and around in 22+ States and other parts of India & abroad, which is certified, approved, recognised & licensed under the Act of Central Govt of India.",
    desc: "TSSR Council is a pioneering force in education franchising across India. With our registered office in New Delhi and central administrative hub in Calicut, Kerala, TSSR Council has rapidly grown into one of India's fastest-growing education franchise organizations. TSSR Council offers over 1400+ certified courses spanning a diverse range of disciplines, ensuring that students receive ISO and other internationally recognized certifications upon completion. Our network boasts more than 1000+ Authorized Training Centers, strategically located across Kerala and in 22+ states throughout India and abroad. Our paramedical courses are affiliated with esteemed bodies like the Delhi State Government Paramedical Council and Lal Bahadur Shastri Paramedical Council, Uttar Pradesh. Similarly, our psychological counseling courses are accredited by the Center for Psychological Training and Research and CIMR. Additionally, TSSR Council is proudly listed by COBSE, further attesting to our commitment to quality education. Join us at TSSR Council, where we empower institutions to deliver exceptional educational experiences and prepare students for a successful future in their chosen fields. Discover why we are recognized as a leading global talent development organization, dedicated to shaping the next generation of skilled professionals. Established to elevate skill development and technical education, TSSR Council is a statutory body established or registered under the Government of India (Reg No. 111/04/ VOL.16/20 - ITA 1882) and conducts courses and issues certificates by the order of the Supreme Court (AIR 1993 SC 2018). Our paramedical courses are recognized as equivalent to those of the Delhi State Government Paramedical Council (Order No. DSGPC/TSSR/359/2023) and the Lal Bahadur Shastri Paramedical Council, Uttar Pradesh (Order No. LBSPC/141/2023).",
  },
];

const sponsorList = [
  {
    imgUrl: "assets/images/sponsor/TssrCouncil.png",
    imgAlt: "TSSR Council",
  },

  {
    imgUrl: "assets/images/sponsor/EGAC.png",
    imgAlt: "EGAC",
  },
  {
    imgUrl: "assets/images/sponsor/EIAC-Logo.png",
    imgAlt: "EIAC",
  },
  {
    imgUrl: "assets/images/sponsor/IncomeTaxDept.png",
    imgAlt: "Income Tax Dept",
  },
  {
    imgUrl: "assets/images/sponsor/IsoCertifiedCompany.png",
    imgAlt: "ISO Certified",
  },
  {
    imgUrl: "assets/images/sponsor/MakeInIndia.png",
    imgAlt: "Make In India",
  },
  {
    imgUrl: "assets/images/sponsor/Opastaja.png",
    imgAlt: "Opastaja",
  },
];

const blogList = [
  {
    imgUrl: "assets/images/blog/steps.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "STEPS T.T.C ACADEMY",
    desc: " KONDOTTY",
  },
  {
    imgUrl: "assets/images/blog/ease.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "EASE EDUCATIONAL HUB",
    desc: "EDAPPAL",
  },
  {
    imgUrl: "assets/images/blog/clt.jpeg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "CHIROPRACTIC ACADEMY ",
    desc: "KOZHIKKODE",
  },

  {
    imgUrl: "assets/images/blog/womens.jpg",
    imgAlt: "blog thumb rajibraj91 rajibraj",
    title: "AL WARDA WOMEN'S COLLEGE",
    desc: "VENGARA, MONNIYUR, VENNIYUR",
  },
];

const options = {
  fullScreen: {
    enable: true,
    zIndex: -1,
  },
  particles: {
    number: {
      value: 200,
      limit: 300,
      density: {
        enable: true,
        value_area: 800,
      },
    },
    color: {
      value: "#000000",
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000",
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: "images/github.svg",
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.5,
      random: true,
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.5,
        sync: false,
      },
    },
    size: {
      value: 30,
      random: true,
      anim: {
        enable: true,
        speed: 10,
        size_min: 10,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 100,
      color: "#ffffff",
      opacity: 1,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onHover: {
        enable: true,
        mode: "bubble",
        parallax: {
          enable: false,
          force: 60,
          smooth: 10,
        },
      },
      onClick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        lineLinked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 100,
        duration: 2,
        opacity: 1,
        speed: 2,
      },
      repulse: {
        distance: 200,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  backgroundMask: {
    enable: true,
    cover: {
      color: {
        value: {
          r: 255,
          g: 255,
          b: 255,
        },
      },
    },
  },
  retina_detect: true,
  fps_limit: 60,
  background: {
    image: "url('https://particles.js.org/images/background3.jpg')",
  },
};

const AboutUsPage = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  return (
    <Fragment>
      <Header />
      <Particles options={options} init={particlesInit} />

      <div className="pageheader-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pageheader-content text-center">
                <h1 style={{ fontFamily: "Raleway" }}>About Us</h1>
                <div
                  className="about-subtitle1"
                  style={{ fontSize: 22, fontWeight: "normal" }}
                >
                  Get Franchise for starting a course at low <br /> investment
                  to provide quality education.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-section">
        <div className="container">
          {/* <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse"> */}
          <div className="col">
            <div className="about-right padding-tb">
              <div
                className="section-header"
                style={{ backgroundColor: "#fff9f1", padding: 20 }}
              >
                <center>
                  <span className="subtitle">{aboutSubTitle}</span>
                  <h2 className="title">{aboutTitle}</h2>
                </center>
              </div>
              <div
                className=""
                style={{ backgroundColor: "#fff9f1", padding: 20 }}
              >
                <ul className="lab-ul">
                  {aboutList.map((val, i) => (
                    <li key={i}>
                      {/* <div className="sr-left">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div> */}
                      <div className="sr-right">
                        {/* <h5>{val.title}</h5> */}
                        <p
                          style={{
                            fontSize: 18,
                            fontFamily: "Fuzzy Bubbles",
                          }}
                        >
                          {val.desc}
                        </p>
                      </div>
                      <div style={{ height: "20px" }}></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col">
              <div className="about-left">
                <div className="about-thumb">
                  <img src="assets/images/about/../banner/01.png" alt="about" />
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>

      <div className="blog-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{WhatWeDoSubTitle}</span>
            <h2 className="title">{WhatWeDoTitle}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 justify-content-center g-4">
              {blogList.map((val, i) => (
                <div className="col" key={i}>
                  <div className="post-item">
                    <div className="post-inner">
                      <div className="post-thumb">
                        <Link to="/blog-single">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </Link>
                      </div>
                      <div className="post-content">
                        <Link to="/blog-single">
                          <h4 style={{ fontSize: 27 }}>{val.title}</h4>
                        </Link>
                        <div className="meta-post">
                          <ul className="lab-ul">
                            {/* <li><i className="icofont-ui-user"></i>{val.author}</li> */}
                            {/* <li><i className="icofont-calendar"></i>{val.date}</li> */}
                          </ul>
                        </div>
                        <p style={{ fontSize: 23, fontWeight: "bold" }}>
                          {val.desc}
                        </p>
                      </div>
                      <div className="post-footer">
                        <div className="pf-left">
                          {/* <Link to="/blog-single" className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></Link> */}
                        </div>
                        <div className="pf-right">
                          {/* <i className="icofont-comment"></i> */}
                          {/* <span className="comment-count">{val.commentCount}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="sponsor-section section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="sponsor-slider">
              <Swiper
                spaceBetween={20}
                slidesPerView={2}
                loop={"true"}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  0: {
                    width: 0,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 3,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 5.5,
                  },
                }}
              >
                {sponsorList.map((val, i) => (
                  <SwiperSlide key={i} style={{ alignContent: "center" }}>
                    <div className="sponsor-iten">
                      <div className="sponsor-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="map-address-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {contactList.map((val, i) => (
                    <div className="contact-item" key={i}>
                      <div className="contact-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="contact-content">
                        <h6 className="title">{val.title}</h6>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-12">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AboutUsPage;
