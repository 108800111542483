import { Link, NavLink } from "react-router-dom";

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "About us";
const useTitle = "Address";
const socialTitle = "Contact Us";
const supportTitle = "Support Links";

const siteList = [
  {
    text: "TSSR Council is Providing Franchise to Authorized Training Centers / Skill development or Testing centers and Industries collaborative programmes as a Promoting Agency of Science, Technical, Computer Programmes & Skill Development.",
    link: "#",
  },
];

const useList = [
  {
    text: "Central Administrative Office:",
    link: "#",
  },
  {
    text:"TSSR COUNCIL, TSSR BHAVAN, Thamarassery, Calicut, Kerala, 673573",
    link:'#',
  },
  // {
  //   text: "TSSR COUNCIL",
  //   link: "#",
  // },
  // {
  //   text: "Ellokara, Kozhikode",

  //   link: "#",
  // },
  // // {
  // //     text: 'Puthupadi, Calicut,',
  // //     link: '#',
  // // },
  // {
  //   text: "Kerala, India - 673586 ",
  //   link: "#",
  // },
  {
    text:"",
    link:"#",
  },

  {
    text: "Registered Office:",
    link: "#",
  },
  {
    text: "New Delhi B - 38 UGF, Vishal Enclave, Rajouri Garden, New Delhi - 11002",
    link: "#",
  },
  // {
  //   text: "New Delhi - 11002",
  //   link: "#",
  // },
];

const socialList = [
  {
    text: "Customer care - ",
    link: "#",
  },
  {
    text: "+91 9400867461",
    link: "#",
  },
  {
    text: "Adiministration - ",
    link: "#",
  },
  {
    text: "+91 8848426310",
    link: "#",
  },
  {
    text: "Email- ",
    link: "#",
  },

  {
    text: "tssrcouncil@gmail.com",
    link: "#",
  },
];

const supportList = [
  {
    text: "Registration Details",
    link: "/accreditation",
  },
  {
    text: "Certificate Verification",
    link: "/online-verification",
  },
  {
    text: "Careers",
    link: "/coming_soon",
  },
  {
    text: "Study Centres",
    link: "/atc-verification",
  },
  {
    text: "Marklist Model",
    link: "/tssr-downloads",
  },

  {
    text: "Contact Us",
    link: "/contact_us",
  },
];

const Footer = () => {
  return (
    <div className="news-footer-wrap">
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>

      <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <form action="/">
                <div className="nf-list">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <input type="submit" name="submit" value="Subscribe Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="footer-top padding-tb pt-0">
          <div className="container">
            <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <NavLink to={val.link}>{val.text}</NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{useTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {useList.map((val, i) => (
                            <li key={i}>
                              <NavLink to={val.link}>{val.text}</NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <NavLink to={val.link}>{val.text}</NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <NavLink to={val.link}>{val.text}</NavLink>
                            </li>
                          ))}
                          <a
                            href="https://www.dropbox.com/scl/fi/dvcnvg261rt1t4pwl22mp/TASC_App.apk?rlkey=ma7kt82ncxxgxb90qu8uk6vkf&dl=1"
                            style={{ color: "white" }}
                          >
                            Official App for Franchises: Access Here
                          </a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper">
              <p>
                &copy; 2016 <Link to="/">TSSR Council.</Link> All rights
                reserved | Powered by{" "}
                <a href="http://www.neptunemark.in/" target="_blank">
                  NeptuneMark
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
